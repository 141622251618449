import type { CustomerServiceProps } from "~/components/Banner/Help/CustomerService.props"
import type { CardProps } from "~/components/Banner/Help/Utility/Card.props"
import { customPaths } from "~/utils/constants"

export const useBannerHelp = () => {
  const { t } = useI18n()

  const listHelpCards: CardProps[] = [
    {
      iconType: "customerServiceOrder",
      title: t("homepage.bannerCustomerService.card1.title"),
      text: t("homepage.bannerCustomerService.card1.text"),
      contact: t("homepage.bannerCustomerService.card1.contact"),
      hrefContact: customPaths.findOrder,
      theme: "green"
    },
    {
      iconType: "customerServiceReceipt",
      title: t("homepage.bannerCustomerService.card2.title"),
      text: t("homepage.bannerCustomerService.card2.text"),
      contact: t("homepage.bannerCustomerService.card2.contact"),
      theme: "green",
      hrefContact: customPaths.findOrder
    },
    {
      iconType: "customerServiceCall",
      title: t("homepage.bannerCustomerService.card3.title"),
      text: t("homepage.bannerCustomerService.card3.text"),
      contact: t("general.supportPhone.label"),
      hrefContact: t("general.supportPhone.link"),
      theme: "green"
    },
    {
      iconType: "customerServiceChat",
      title: t("homepage.bannerCustomerService.card4.title"),
      text: t("homepage.bannerCustomerService.card4.text"),
      contact: t("homepage.bannerCustomerService.card4.contact"),
      hrefContact: customPaths.contactUs,
      theme: "green"
    },
    {
      iconType: "customerServiceFaq",
      title: t("homepage.bannerCustomerService.card5.title"),
      text: t("homepage.bannerCustomerService.card5.text"),
      contact: t("homepage.bannerCustomerService.card5.contact"),
      hrefContact: customPaths.faqs,
      theme: "green"
    },
    {
      iconType: "customerServiceMail",
      title: t("homepage.bannerCustomerService.card6.title"),
      text: t("homepage.bannerCustomerService.card6.text"),
      contact: t("homepage.bannerCustomerService.card6.contact"),
      hrefContact: customPaths.contactUs,
      theme: "green"
    }
  ]

  const customerService: CustomerServiceProps = {
    title: t("homepage.bannerCustomerService.title"),
    caption: t("homepage.bannerCustomerService.caption"),
    text: t("homepage.bannerCustomerService.text"),
    listHelpCard: listHelpCards.filter((card) =>
      [
        "customerServiceCall",
        "customerServiceChat",
        "customerServiceFaq"
      ].includes(card.iconType)
    ),
    bgImage: {
      focalPoint: {
        x: 273,
        y: 100
      },
      activateFocalPoint: true,
      altText: "Sfondo verde con macchie",
      base64:
        "data:image/jpeg;base64,/9j/2wBDABQODxIPDRQSEBIXFRQYHjIhHhwcHj0sLiQySUBMS0dARkVQWnNiUFVtVkVGZIhlbXd7gYKBTmCNl4x9lnN+gXz/2wBDARUXFx4aHjshITt8U0ZTfHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHz/wAARCAAJABADASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAABAX/xAAcEAEAAgEFAAAAAAAAAAAAAAABAAIyAxESFCP/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oADAMBAAIRAxEAPwB5Z5i2GO7HnsJI2llFXwipH//Z",
      image: {
        url: "https://images.ctfassets.net/i5oh5hzamkwo/1LgKOhZjCJDZ8zGy0Iaeq1/6ad656e84496895984f014bc6fcdf58c/bg-banner-servizio-clienti.jpg",
        height: 785,
        width: 1321
      }
    },
    isCheckout: true
  }

  const customerServiceHomepage: CustomerServiceProps = {
    ...customerService,
    isCheckout: false
  }

  return { listHelpCards, customerServiceHomepage, customerService }
}
